.App {
  text-align: center;
}

.top-picture {
  background-color: white ;
  aspect-ratio: 12/1;
  padding-bottom: 0;
}

.top-picture img {
  border-radius: 0;
  height: 100%;
  width: auto;
  padding: 0;
}

.navbar {
  padding: 1%;
  background-color: black;
  color: white;
}

.navbar a{
  color: white;
  padding: 2%;
}

.navbar a:hover{
  color: rgb(183, 183, 183);
}

.navbar a:visited:hover{
  color: rgb(183, 183, 183);
}

.navbar a:visited{
  color: white;
}


.inline-left-small {
  text-align: right;
  display: inline-block;
  width: 24%;
  padding-right: 3%;
  padding-left: 6%;
}
.inline-right-big {
  text-align: left;
  display: inline-block;
  width: 49%;
  padding-left: 3%;
  padding-right: 6%;
  vertical-align: top;
}

img {
  padding: 2%;
  width: 100%;
  border-radius: 20%;
}

h1 {
  margin-top: 0%;
  font-size: x-large;
}

@media only screen and (max-width: 400px) {
  .top-picture {
    aspect-ratio: 4/1;
  }

  .top-picture img {
    width: 100%;
  }

  .inline-left-small{
    display: block;
    width: 85%;
    padding-left: 8%;
  }

  .inline-right-big{
    display: block;
    width: 85%;
    padding-right: 6%;

  }
}