.banner {
  font-size:xx-large;
  background-color: rgb(219,217,182) ;
  text-align: center;
  aspect-ratio: 64/18;
}

.banner img{
  border-radius: 0;
  padding: 0%;
  height: 100%;
  object-fit: contain;
  position: relative;
}

.centered-banner {
  text-decoration: underline;
  color: white;
  position: absolute;
  text-align: center;
  width: 100%;
  top: 20vw;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

}


.generic-div {
  height: 25%;
  background-color: rgb(219,217,182) ;
  margin-bottom: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.sub-generic-div-left {
  width: 35%;
  text-align: left;
  padding-right: 2%;
  padding-left: 10%;
  display: inline-block;
  vertical-align: top;
  direction: rtl;
}

.sub-generic-div-right {
  width: 35%;
  text-align: left;
  padding-left: 2%;
  padding-right: 10%;
  display: inline-block;
}

.portrait {
  padding-top: 0;
  left: 20%;
  position: relative;
  height: 30vw;
  width: auto;
}

@media only screen and (max-width: 800px) {
  .centered-banner {
    top: 20vw;
    font-size: large;
  }
}

@media only screen and (max-width: 600px) {
  .centered-banner {
    top: 25vw;
    font-size: large;
  }

  .portrait {
    height: 50vw;
  }
}

@media only screen and (max-width: 400px) {
  .centered-banner {
    top: 40vw;
    font-size: large;
  }

  .sub-generic-div-left {
    display: block;
    width: 70%;
  }

  .sub-generic-div-right {
    display: block;
    padding-left: 10%;
    width: 70%;
  }

  .portrait {
    padding-top: 3%;
    left: 20%;
    height: 70vw;
  }
}

@media only screen and (max-width: 300px) {
  .centered-banner {
    top: 50vw;
    font-size: large;
  }
}