.map {
  width: 80%;
  height: 25vw;
  padding: 2vw;
  padding-left: 0%;
}

@media only screen and (max-width: 600px) {
  .map {
    height: 75vw;
  }
}

.contact {
  padding: 2%;
  height: 50%;
  background-color: beige ;
}

.inline {
  display: inline-block;
  padding: 3%;
  padding-top: 1%;
}

.message {
  text-align: center
}

.submit {
  text-align: center;
  width: 10%;
}

input {
  width: 100%;
}

.left {
  margin-bottom: 0;
  text-align: left;
  padding-left: 15%;
  font-weight: 300;
}

@media only screen and (max-width: 900px) {
  textarea {
    width: 95%;
  }
}

@media only screen and (max-width: 600px) {
  .submit {
    width: fit-content;
  }
}