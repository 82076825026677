.generic-div-white {
  background-color: rgb(254, 254, 254);
  padding: 5vw;
}

.generic-div-black {
  background-color: rgb(44,44,44) ;
  color: white;
  padding: 5vw;

}

h2 {
  font-size: large;
  font-style: italic;
}

